<script setup>
import { inject } from 'vue';

import Title from '@/Components/Title/Title.vue';
import FilterDropdown from '@/Components/Table/FilterDropdown.vue';
import { useI18n } from 'vue-i18n';
import AutoComplete from '@/Components/Input/AutoComplete.vue';

const route = inject('route');
const { t } = useI18n();

// Variables
const props = defineProps({
    form: Object,
    h1: String,
    data: Object,
    typeFilters: Object,
    hasSearch: Boolean,
    positionOptions: Object,
    locationOptions: Object,
    flexerOptions: Object,
    categoryOptions: Object,
});

const emits = defineEmits(['anyUpdate', 'searchUpdate']);

const ratingOptions = [
    { value: '', label: 'Reviews' },
    { value: 1, label: '1+' },
    { value: 2, label: '2+' },
    { value: 3, label: '3+' },
    { value: 4, label: '4+' },
    { value: 5, label: '5' },
];

const statusOptions = [
    { value: '', label: 'Status' },
    { value: 'open', label: 'open' },
    { value: 'completed', label: 'completed' },
];

const favoriteOptions = [
    { value: 'all', label: t('All fl@xrs') },
    { value: 'favorites', label: t('Favorites') },
];
</script>

<template>
    <Title :title="h1">
        <FormKit
            v-if="form && hasSearch"
            v-model="form.search"
            type="text"
            :placeholder="$t('Search')"
            name="search"
            suffix-icon="search"
            :value="form.search"
            :classes="{
                prefixIcon: 'left-[270px]',
                suffix: 'bg-white',
            }"
            @input="emits('searchUpdate')"
        />

        <FilterDropdown
            v-if="typeof typeFilters.status != 'undefined'"
            v-model:value="form.status"

            :dropdown-label="$t('Status')"
            :options="typeFilters.status.options ?? statusOptions"
            @update:value="emits('anyUpdate')"
        />
        <FilterDropdown
            v-if="typeof typeFilters.category != 'undefined'"
            v-model:value="form.category_id"

            :dropdown-label="$t('Status')"
            :options="typeFilters.category.options"
            @update:value="emits('anyUpdate')"
        />

        <FilterDropdown
            v-if="typeof typeFilters.week != 'undefined'"
            v-model:value="form.week"
            :data="data"
            :dropdown-label="$t('Week')"
            :options="typeFilters.week.options ?? statusOptions"
            @update:value="emits('anyUpdate')"
        />

        <FilterDropdown
            v-if="typeFilters.position === 'Position'"
            v-model:value="form.position_id"
            :data="data"
            :dropdown-label="$t('Position')"
            :options="{ '': 'Selecteer een functie', ...positionOptions }"
            @update:value="emits('anyUpdate')"
        />

        <AutoComplete
            v-if="typeFilters.location === 'Company'"
            v-model="form.location_id"
            :items="locationOptions"
            :placeholder="$t('Selecteer een bedrijf')"
            @update:modelValue="emits('anyUpdate')"
        />


        <AutoComplete
            v-if="typeFilters.flexer === 'Flexer'"
            v-model="form.flexer_id"
            :items="flexerOptions"
            :placeholder="$t('Selecteer een Fl@xr')"
            @update:modelValue="emits('anyUpdate')"
        />

        <FilterDropdown
            v-if="typeFilters.rating === 'Rating'"
            v-model:value="form.minimum_rating"
            :data="data"
            :dropdown-label="$t('Rating')"
            :options="ratingOptions"
            @update:value="emits('anyUpdate')"
        />

        <FilterDropdown
            v-if="typeFilters.category === 'FAQCategory'"
            v-model:value="form.category_id"
            :data="data"
            class="z-10"
            :dropdown-label="$t('Category')"
            :options="{ '': 'Selecteer een categorie', ...categoryOptions }"
            @update:value="emits('anyUpdate')"
        />

        <FilterDropdown
            v-if="typeFilters.favorite === 'Favorite'"
            v-model:value="form.favorite"
            :data="data"
            :dropdown-label="$t('Favorites')"
            :options="favoriteOptions"
            @update:value="emits('anyUpdate')"
        />
        <slot />
    </Title>
</template>
