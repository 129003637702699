<script setup>
import { computed } from 'vue';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';

const props = defineProps({
    options: {},
    dropdownLabel: String,
    value: {},
});

const emits = defineEmits(['update:value']);

const currentLabel = computed(() => {
    if (Array.isArray(props.options)) {
        return props.options.find((option) => option.value == props.value)?.label;
    }

    return props.options[props.value];
});
</script>

<template>
    <div class="z-10 flex flex-1 gap-4">
        <div class="flex flex-col items-end">
            <Menu
                v-slot="{ open }"
                as="div"
                class="relative min-w-50"
            >
                <MenuButton class="flex-between w-full focus:z-10">
                    <div
                        v-if="dropdownLabel"
                        class="flex w-full items-center justify-between rounded border border-gray-medium px-3 py-2"
                    >
                        <p
                            v-if="typeof value != 'undefind'"
                            class="text-base text-blue"
                            v-html="currentLabel"
                        />
                        <p
                            v-else
                            class="text-base text-blue"
                            v-html="dropdownLabel"
                        />

                        <div class="">
                            <span class="sr-only">Change published status</span>
                            <img
                                src="@/../img/icon/blue-dropdown-arrow-down.svg"
                                class="ml-2 h-4 w-4 duration-100"
                                :class="{ 'rotate-180': open }"
                                aria-hidden="true"
                            >
                        </div>
                    </div>
                </MenuButton>

                <transition
                    enter-active-class="transition duration-100 ease-out"
                    enter-from-class="transform scale-95 opacity-0"
                    enter-to-class="transform scale-100 opacity-100"
                    leave-active-class="transition duration-75 ease-in"
                    leave-from-class="transform scale-100 opacity-100"
                    leave-to-class="transform scale-95 opacity-0"
                >
                    <MenuItems
                        v-if="options"
                        class="divide-gray-500-100 absolute right-0 mt-2 min-w-50 origin-top-right divide-y overflow-hidden rounded-md  bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                    >
                        <div
                            v-for="(option, index) in options"
                            :key="option.value"
                        >
                            <div v-if="typeof option === 'string'">
                                <MenuItem v-slot="{ active }">
                                    <button
                                        :class="active ? 'bg-orange text-white' : 'text-blue'"
                                        class="flex min-h-10 w-full justify-between py-2 pl-3 pr-4 text-left text-sm"
                                        @click="emits('update:value', index)"
                                    >
                                        {{ option }}
                                    </button>
                                </MenuItem>
                            </div>
                            <div v-else>
                                <MenuItem v-slot="{ active }">
                                    <button
                                        :class="active ? 'bg-orange text-white' : 'text-blue'"
                                        class="flex min-h-10 w-full items-center justify-between py-2 pl-3 pr-4 text-left  text-sm"
                                        @click="emits('update:value', option.value)"
                                    >
                                        {{ option.label }}
                                    </button>
                                </MenuItem>
                            </div>
                        </div>
                    </MenuItems>
                </transition>
            </Menu>
        </div>
    </div>
</template>
