<script setup>
import Errors from '@/Components/Input/Errors.vue';
import Label from '@/Components/Input/Label.vue';
import AutoComplete from 'primevue/autocomplete';
import { computed, ref } from 'vue';

const props = defineProps({
    label: String,
    modelValue: String,
    items: [Object, Array],
    errors: Object,
    disabled: Boolean,
    multiple: Boolean,
    placeholder: String,
});

const emits = defineEmits(['update:modelValue']);

const hasErrors = computed(() => {
    return props.errors && props.errors.length > 0;
});

const itemsAsArray = computed(() => {
    if (Array.isArray(props.items)) {
        return props.items;
    }
    return Object.entries(props.items).map(([value, label]) => ({ value, label }));
});


const modelItem = computed(() => {
    if (props.multiple) {
        if (props.modelValue && props.modelValue.length) {
            return itemsAsArray.value.filter(item => props.modelValue.includes(item.value));
        }
        return [];
    }
    return itemsAsArray.value.find(item => item.value === props.modelValue) ?? props.modelValue;
});

const filteredItems = ref();
const searchItems = (event) => {

    if (!event.query.trim().length) {
        filteredItems.value = [...itemsAsArray.value];
    } else {
        filteredItems.value = itemsAsArray.value.filter((item) => {
            return item.label.toLowerCase().startsWith(event.query.toLowerCase());
        });
    }

};

const valueUpdated = (selected) => {
    if (selected && typeof selected === 'object' && 'value' in selected) {
        emits('update:modelValue', selected.value);
    } else if (props.multiple && selected && selected.length && selected[0].hasOwnProperty('value')) {
        let selectedIds = selected.flatMap(i => i.value);
        emits('update:modelValue', selectedIds);
    } else {
        emits('update:modelValue', selected);
    }
};

</script>

<template>
    <div class="w-full">
        <Label
            v-if="label"
            :label="label"
        />
        <AutoComplete
            :class="{'p-invalid': hasErrors}"
            :disabled="disabled"
            :label="label"
            :model-value="modelItem"
            :multiple="multiple"
            :placeholder="placeholder"
            :suggestions="filteredItems"
            :virtual-scroller-options="{ itemSize: 40 }"
            complete-on-focus
            dropdown
            force-selection
            option-label="label"
            @complete="searchItems($event)"
            @update:model-value="(selected) => valueUpdated(selected)"
        />
        <Errors :errors="errors" />
    </div>
</template>

